import ajuarez from "./assets/ajuarezB.svg";
import in_logo from "./assets/in.svg";
import x_logo from "./assets/x.svg";
function App() {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center gap-4 px-8" >
      <p className="text-3xl font-bold">PRÓXIMAMENTE</p>
      <img src={ajuarez} className="my-8" alt="logo" />
      {/* <p className="text-xl uppercase">Sitio en construcción</p> */}
      <div className="bg-green-900 p-6 rounded flex flex-col gap-7 items-center">
        <span className="text-white font-light w-[300px] text-xs lg:text-base text-center">Para cualquier consulta, no dudes en comunicarte conmigo a través de:</span>
        <div className="flex flex-row gap-5">
          <a href="https://www.linkedin.com/in/ajuarez93/" target="_blank"><img src={in_logo} className="w-16 lg:w-20" alt="logo" /></a>
          <a href="https://x.com/_ajuarez93" target="_blank"><img src={x_logo} className="w-16 lg:w-20" alt="logo" /></a>
        </div>
      </div>
    </div>
  );
}

export default App;
